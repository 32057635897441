<template>
  <div class="menu" @click="$store.commit('SET_ISMENUOPEN', false)">
    <div class="menu__content">
      <div class="menu__container">
        <span 
          v-for="(period, index) in timePeriods"
          :key="period.name"
          v-html="period.name"
          class="menu__item"
          :style=" period.name === currentPeriodData.name ? 'color:' + period.color : '' "
          :id="index"
          @click="goToPeriod"
        />
      </div>
    <Share class="menu__share" v-if="!$store.state.isDesktop" />
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap';
import Share from './Share';
  export default {
    components: {
      Share,
    },
    watch: {
      isMenuOpen(newValue) {
        if(newValue) {
          this.tl.play();
          document.body.style.overflow = 'hidden';
        } else {
          !this.isDesktop ? document.body.style.overflow = 'scroll' : 0;
          //document.body.style.overflow = 'scroll';
          this.tl.reverse();
        }
        //newValue ? this.tl.play() : this.tl.reverse();
      }
    },
    data() {
      return {
        tl: null
      }
    },
    mounted() {
      this.tl = gsap.timeline({ paused: true });
      this.tl
        .set('.menu', { zIndex: 3 })
        .to('.menu', {
          duration: 0.3,
          background: 'rgba(5, 5, 5, 0.29)'
        })
        .to('.menu__content', {
          duration: 0.3,
          xPercent: -100,
          autoAlpha: 1
        }, 0)
    },
    methods: {
      goToPeriod({ target }) {
        this.sendGa(target.id);
        
        if(this.isDesktop) {
          //12 - animationLangth in component animation
          //const scrollHeight = document.documentElement.clientHeight * 12;
          const scrollHeight = Number(document.querySelector('.pin-spacer').style.padding.split(' ')[2].replace('px', ''));
          const periodIndex = target.id;
          gsap.to(window, {
            duration: 1,
            scrollTo: scrollHeight / 4 * periodIndex
          })
          return;
        }
        this.$store.commit('SET_CURRENTPERIOD', target.id);
        this.$store.commit('SET_ISMENUOPEN', false);
        gsap.to(window, {
          duration: 1,
          scrollTo: '#period' + target.id
        })
      },
      sendGa(id) {
        window.ga('send',{hitType:'event',eventCategory:'all',eventAction:'click',eventLabel:`sect${id + 1}`,eventValue:1});

        const years = ['2007', '2012', '2015', '2017', '2019'];
        window.ga('send',{hitType:'event',eventCategory:'menu',eventAction:'click',eventLabel:years[id],eventValue:1});
      }
    },
    computed: {
      timePeriods() {
        return this.$store.state.timePeriods;
      },
      currentPeriodData() {
        return this.$store.getters.currentPeriodData;
      },
      isMenuOpen() {
        return this.$store.state.isMenuOpen;
      },
      isDesktop() {
        return this.$store.state.isDesktop;
      }
    }
  }
</script>

<style lang="scss" scoped>
.menu {
  z-index: -1;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  &__share {
    display: none;
    @include mobile {
      display: block;
    }
  }
  &__content {
    opacity: 0;
    width: 14.8em;
    height: 100%;
    background: white;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    position: fixed;
    top: 0;
    right: 0;
    padding: 5.5em 0 0 1.5em;
    transform: translateX(100%);
    @include mobile {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 7.3em 0 1.25em 3em;
      width: 22.5em;
    }
  }
  &__container {
    display: flex;
    flex-direction: column;
  }
  &__item {
    cursor: pointer;
    font-family: 'Druk Text Wide Cyr';
    font-weight: 900;
    font-size: 1.53em;
    letter-spacing: -0.025em;
    color: #EFEFEF;
    margin-bottom: 1em;
    transition: .2s linear;
    &:last-of-type {
      margin: 0;
    }
    @include mobile {
      margin-bottom: 1.25em;
      font-size: 2em;
    }
  }
}
</style>