<template>
  <div class="static-container">
    <div class="static">
      <Share class="static__share" />
      <svg class="static__avito" @click="$store.commit('SET_APPSTATE', 'Home'); sendGa('logo')">
        <use xlink:href="#mobile-avitoLogo"/>
      </svg>
      <p class="static__sgm">sgm &copy;2021</p>
      <transition name="fade">
        <button 
          v-if="this.$store.state.appState !== 'Home' && !this.$store.state.isPeriodShown"
          :class=" ['menu-btn', { 'menu-btn_active': isMenuOpen }] " 
          @click="openMenu"
        >
          <svg class="menu-btn__bg">
            <use xlink:href="#menu" />
          </svg>

          <span class="menu-btn__line" />
          <span class="menu-btn__line" />
          <span class="menu-btn__line" />
        </button>
      </transition>
    </div>

    <div class="static_mobile">
      <svg class="static_mobile-avito" @click="scrollTop">
        <use xlink:href="#mobile-avitoLogo"/>
      </svg>
      <button
        :class=" ['menu-btn', { 'menu-btn_active': isMenuOpen }] " 
        @click="openMenu"
      >
        <svg class="menu-btn__bg">
          <use xlink:href="#menu" />
        </svg>

        <span class="menu-btn__line" />
        <span class="menu-btn__line" />
        <span class="menu-btn__line" />
      </button>
    </div>
  </div>
</template>

<script>
import Share from './Share';
import { gsap } from 'gsap';
  export default {
    components: {
      Share
    },
    computed: {
      isMenuOpen() {
        return this.$store.state.isMenuOpen;
      }
    },
    methods: {
      openMenu() {
        this.sendGa('menu');
        this.$store.commit('SET_ISMENUOPEN', !this.isMenuOpen);
      },
      goHome() {
        console.log('clicked!');
      },
      scrollTop() {
        this.sendGa('logo');
        gsap.to(window, {
          duration: 1,
          scrollTo: 0
        })
      },
      sendGa(event) {
        event === 'logo' ? 
          window.ga('send',{hitType:'event',eventCategory:'main',eventAction:'click',eventLabel:'home',eventValue:1}) :
          window.ga('send',{hitType:'event',eventCategory:'main',eventAction:'click',eventLabel:'menu',eventValue:1});
      }
    }
  }
</script>

<style lang="scss" scoped>
.static {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  @include mobile {
    display: none;
  }
  &__avito {
    cursor: pointer;
    position: fixed;
    width: 2.4em;
    height: 1.5em;
    top: 1em;
    left: 1em;
  }
  &__sgm {
    font-family: 'Druk Text Wide Cyr';
    font-weight: 800;
    font-size: 0.6em;
    letter-spacing: -0.025em;
    position: fixed;
    bottom: 1.8em;
    left: 2.16em;
    text-transform: uppercase;
  }
  &__share {
    position: fixed;
    bottom: 1em;
    right: 1.33em;
  }
  &_mobile {
    display: none;
    @include mobile {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 11;
      &-avito {
        position: fixed;
        left: 1em;
        top: 0.6875em;
        width: 4.25em;
        height: 3em;
      }
    }
  }
}
.menu-btn {
  z-index: 11;
  width: 1.9em;
  height: 1.2em;
  padding: 0.16em 0.4em;
  outline: none;
  border:none;
  cursor: pointer;
  position: relative;
  background: transparent;
  //background: black;
  //box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  position: fixed;
  top: 1.2em;
  right: 1.4em;
  display: flex;
  flex-direction: column;

  @include mobile {
    width: 3.56em;
    height: 3em;
    //height: 2.187em;
    top: 1.125em;
    right: 1.125em;
    padding: 0.6em 0.75em;
  }

  &__bg {
    @include abs-center;
    width: 3.5em;
    height: 3em;
    @include mobile {
      width: 6em;
      height: 5em;
    }
  }

  &__line {
    position: relative;
    width: 1.1em;
    height: 0.13em;
    background: white;
    margin-bottom: 0.13em;
    transition: all .2s linear;
    &:last-of-type {
      margin-bottom: 0;
    }
    @include mobile {
      width: 2.07em;
      height: 0.25em;
      margin-bottom: 0.25em;
    }
  }
  &_active {
    .menu-btn__line:first-of-type {
      transform: translateY(0.26em) rotate(30deg);
      @include mobile {
        transform: translateY(0.5em) rotate(30deg);
      }
    }
    .menu-btn__line:nth-of-type(2) {
      transform: translateX(1em);
      opacity: 0;
    }
    .menu-btn__line:last-of-type {
      transform: translateY(-0.26em) rotate(-30deg);
      @include mobile {
        transform: translateY(-0.5em) rotate(-30deg);
      }
    }
  }
  }
</style>