<template>
  <div>
    <div class="transition" :style=" 'background:' + currentPeriodData.color " />
    <div class="period" ref="periodBg" :style=" 'background:' + currentPeriodData.color ">
      <div class="period__info">
        <img :src=" require('../assets/images/stickers/' + currentSticker.name + '.png') " class="period__sticker">
        <div class="period__text">
          <h2 class="period__date" v-html="currentSticker.date" :style=" 'color:' +  currentPeriodData.nameColor" />
          <h2 class="period__title" v-html="currentSticker.title" />
          <p class="period__text" v-html="currentSticker.text" />
          
          <a 
            v-if="currentSticker.id === '4_7'" 
            href="https://youtu.be/tvaRBrS14rE" 
            target="_blank" 
            class="period__button"
            @click="sendGa('button')"
          >
            Гоу на Ютуб
          </a>
        </div>
      </div>
      <div class="period__pagination">
        <img 
          v-for="item in currentPeriodStickers"
          :key="item.name"
          :src=" require('../assets/images/stickers/' + item.name + '.png') "
          :class=" ['period__pagination-item', { 'period__pagination-item-active': item.id === currentSticker.id }] "
          :id=" item.id "
          @click="setSticker"
        >
      </div>

      <button class="period__back" @click="goBack">
        <svg class="period__arrow">
          <use xlink:href="#back-arrow" />
        </svg>
        Назад
      </button>

      <span 
        @click="({ target }) => changePeriod(target.id)" 
        id="prev" 
        :class="['period__name', {'period__name_hover': currentPeriod !== 0}]"
        :style=" 'color:' + currentPeriodData.nameColor "
      >
        {{ periodName('prev') }}
      </span>
      <span 
        @click="({ target }) => changePeriod(target.id)" 
        id="next" 
        :class="['period__name', {'period__name_hover': currentPeriod !== timePeriods.length - 1}]" 
        :style=" 'color:' + currentPeriodData.nameColor "
      >
        {{ periodName('next') }}
      </span>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap';
  export default {
    props: {
      stickerId: String
    },
    data() {
      return {
        currentSticker: null,
        transitionTl: null
      }
    },
    created() {
      this.transitionTl = gsap.timeline();

      if(this.stickerId) {
        this.currentSticker = this.currentPeriodStickers.find(item => item.id === this.stickerId);
      } else {
        this.currentSticker = this.currentPeriodStickers[0];
      }
    },
    mounted() {
      document.body.style.overflow = 'hidden';
      this.enterPeriodTransitionAnimation();

      window.addEventListener('keydown', (event) => {
        if(this.$store.state.isPeriodShown) {
          const direction = event.key;
          const targetId = direction === 'ArrowRight' ? 'next' : direction === 'ArrowLeft' ? 'prev' : 0;
          targetId ? this.changePeriod(targetId) : 0;
        }
      })
    },

    // beforeDestroy() {
    //   document.body.style.overflow = 'scroll';
    // },

    methods: {
      setSticker({ target }) {
        this.sendGa('pagination');

        this.leaveStickerANimation();
        setTimeout(() => {
          const targetId = target.id;
          this.currentSticker = this.currentPeriodStickers.find(item => item.id === targetId)
          this.enterStickerAnimation();
        }, 760)
      },
      enterStickerAnimation() {
        gsap.timeline()
          .to('.period__sticker', {
            duration: 0.5,
            opacity: 1,
            scale: 1,
          })
          .to('.period__text', {
            duration: 0.5,
            opacity: 1,
            x: 0
          }, '-=0.25')
      },
      leaveStickerANimation() {
        gsap.timeline()
          .to('.period__text', {
            duration: 0.5,
            opacity: 0,
            x: 100
          })
          .to('.period__sticker', {
            duration: 0.5,
            opacity: 0,
            scale: 0.5,
          }, '-=0.25')
      },
      goBack() {
        this.sendGa('back');
        this.leavePeriodTransitionAnimation();

        this.scrollToPeriod(this.currentPeriod);

        setTimeout(() => {
          this.$emit('goBack');
        }, 1950)
      },
      enterPeriodTransitionAnimation() {
        this.transitionTl
          .set('.transition', { opacity: 1 })
          .to('.transition', {
            duration: 0.7,
            scale: 350
          })
          .set('.period', { opacity: 1 })
          .addLabel('transition')
          .from('.period__name:first-of-type', {
            duration: 0.5,
            opacity: 0,
            y: -300,
          })
          .from('.period__name:last-of-type', {
            duration: 0.5,
            opacity: 0,
            y: 300,
          }, '-=0.5')
          .from('.period__sticker', {
            duration: 0.5,
            opacity: 0,
            scale: 0.5,
          })
          .from('.period__text', {
            duration: 0.5,
            opacity: 0,
            x: 100
          }, '-=0.25')
          .from('.period__pagination', {
            duration: 0.5,
            opacity: 0,
            y: 100
          }, '-=1')
      },
      leavePeriodTransitionAnimation() {
        this.transitionTl.reverse();
      },
      sendGa(event) {
        if(event === 'pagination') {
          window.ga('send',{hitType:'event',eventCategory:'all',eventAction:'click',eventLabel:'stickers',eventValue:1});
          window.ga('send',{hitType:'event',eventCategory:'all',eventAction:'click',eventLabel:'sectionmini',eventValue:1});
          window.ga('send',{hitType:'event',eventCategory:`section${this.currentPeriod + 1}`,eventAction:'click',eventLabel:`mini${this.currentPeriod + 1}`,eventValue:1});
          return;
        }
        if(event === 'button') {
          window.ga('send',{hitType:'event',eventCategory:'client',eventAction:'click',eventLabel:'video',eventValue:1});
          return;
        }
        if(event === 'back') {
          window.ga('send',{hitType:'event',eventCategory:'all',eventAction:'click',eventLabel:'backall',eventValue:1});
          window.ga('send',{hitType:'event',eventCategory:`section${this.currentPeriod + 1}`,eventAction:'click',eventLabel:`back${this.currentPeriod + 1}`,eventValue:1});
        }
      },
      periodName(direction) {
        const directionPeriod = direction === 'next' ? this.timePeriods[this.currentPeriod + 1] : this.timePeriods[this.currentPeriod - 1];
        if(directionPeriod) return directionPeriod.name;
        return this.currentPeriodData.name;
      },
      changePeriod(targetId) {
        const nextPeriod = targetId === 'next' ? this.currentPeriod + 1 : this.currentPeriod - 1;
        if(nextPeriod > this.timePeriods.length - 1 || nextPeriod < 0) return;

        this.transitionTl.reverse().tweenTo('transition');
        
        const periodElement = document.querySelector('.period');
        gsap.to(periodElement, {
            duration: 0.2,
            delay: 1.7,
            background: this.timePeriods[nextPeriod].color
        });
        setTimeout(() => {
          this.$store.commit('SET_CURRENTPERIOD', nextPeriod);
          this.currentSticker = this.currentPeriodStickers[0];
          this.transitionTl.play();
          this.scrollToPeriod(nextPeriod);
        }, 1910);
      },
      scrollToPeriod(periodIndex) {
        const scrollHeight = Number(document.querySelector('.pin-spacer').style.padding.split(' ')[2].replace('px', ''));
        gsap.to(window, {
          duration: 0.2,
          scrollTo: scrollHeight / 4 * periodIndex
        })
      }
    },
    computed: {
      currentPeriodData() {
        return this.$store.getters.currentPeriodData;
      },
      currentPeriodStickers() {
        return this.$store.getters.currentPeriodStickers;
      },
      currentPeriod() {
        return this.$store.state.currentPeriod;
      },
      timePeriods() {
        return this.$store.state.timePeriods;
      }
    }
  }
</script>

<style lang="scss" scoped>
.transition {
  width: 12px;
  height: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  opacity: 0;
}
.period {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  padding: 6em 9em 0 9.5em;
  padding-top: 17vh;
  &__name {
    z-index: -1;
    display: block;
    font-family: 'Druk Text Wide Cyr';
    font-weight: 900;
    font-size: 3.2em;
    line-height: 89.3%;
    letter-spacing: -0.025em;
    position: absolute;
    transition: text-shadow .3s ease-out;
    cursor: default;

    &_hover:hover {
      cursor: pointer;
      text-shadow: 0px 1px 10px rgba(0, 0, 0, 0.35);
    }

    &:first-of-type {
      transform-origin: top left;
      transform: rotate(-90deg);
      top: calc(8.2em + 10vh);
      left: calc(0em + 0.3em);
    }
    &:last-of-type {
      transform-origin: top right;
      transform: rotate(-90deg);
      top: calc(0em + 10vh);
      right: calc(1em + 0.4em);
    }
  }
  &__back {
    background: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;

    font-family: 'Druk Text Wide Cyr';
    font-weight: 800;
    font-size: 0.6em;

    position: absolute;
    top: 1.5em;
    right: 2.6em;
  }
  &__arrow {
    width: 0.78em;
    height: 1em;
    margin-right: 1.2em;
  }
  &__info {
    display: flex;
    //align-items: center;
    justify-content: space-between;
  }
  &__sticker {
    width: 15em;
    height: 15em;
  }
  &__text {
    width: 27em;
  }
  &__date {
    font-size: 2em;
    margin-bottom: 0.45em;
  }
  &__title {
    margin-bottom: 0.6em;
  }
  &__button {
    outline: none;
    border: none;
    color: white;
    font-family: 'Druk Text Wide Cyr';
    font-weight: 800;
    font-size: 0.6em;
    letter-spacing: -0.025em;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 13.77em;
    height: 3.61em;
    background: #000000;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
    border-radius: 54px;
    margin-top: 2em;

    transition: all .3s linear;

    @include mobile {
      font-size: 1em;
      width: 11.25em;
      height: 3.125em;
    }

    &:hover {
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.55);
    }
  }
  &__pagination {
    position: absolute;
    width: 40em;
    bottom: 3em;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    &-item {
      width: 2.6em;
      filter: grayscale(100%);
      transition: all .2s ease-out;
      cursor: pointer;
      &-active {
        transform: scale(1.1) rotate(5deg);
        filter: none;
      }
      &:hover {
        transform: scale(1.1) rotate(5deg);
        filter: none;
      }
    }
  }
}
</style>