import Vue from 'vue'
import App from './App.vue'
import store from './store'
import { Share } from './utils/share';
import { preloadImages, preloadFonts } from './utils/preload';

Vue.prototype.$preloadImages = preloadImages;
Vue.prototype.$preloadFonts = preloadFonts;
Vue.prototype.$share = Share;
Vue.config.productionTip = false

new Vue({
  store,
  render: h => h(App)
}).$mount('#app')
