<template>
  <!-- <div class="stickers">
    <svg class="stickers__close" @click="$store.commit('SET_ISPERIODSHOWN', false)">
      <use xlink:href="#close" />
    </svg> -->
  <div class="swiper-container">
    <div class="swiper-wrapper">
      <div 
        v-for="sticker in currentPeriodStickers"
        :key="sticker.id"
        class="swiper-slide"
      >

      <div class="mobile-stickers">
        <svg class="mobile-stickers__close" @click="$store.commit('SET_ISPERIODSHOWN', false)">
          <use xlink:href="#close" />
        </svg>

        <img :src=" require('../assets/images/stickers/' + sticker.name + '.png') " class="mobile-stickers__img">
        <div class="mobile-stickers__info">
          <h2 class="mobile-stickers__date">{{ sticker.date }}</h2>
          <h2 class="mobile-stickers__title">{{ sticker.title }}</h2>
          <p class="mobile-stickers__text">{{ sticker.text }}</p>
          <a 
            v-if="sticker.id === '4_7'" 
            href="https://youtu.be/tvaRBrS14rE" 
            target="_blank" 
            class="mobile-stickers__button"
            @click="sendGa"
          >
            Гоу на Ютуб!
          </a>
        </div>
        </div>

      </div>

    </div>
    <div class="swiper-pagination"></div>
  </div>
    <!-- <img :src=" require('../assets/images/stickers/' + currentSticker.name + '.png') " class="stickers__img">
    <div class="stickers__info">
      <h2 class="stickers__title">{{ currentSticker.title }}</h2>
      <p class="stickers__text">{{ currentSticker.text }}</p>
    </div> -->

    <!-- <div class="stickers__navigation">
      <span class="stickers__pagination">{{ (currentStickerIndex + 1) + ' / ' + totalStickers }}</span>
      <div class="stickers__btns">
        <button class="stickers__btn stickers__btn_left" >
          <svg class="stickers__btn-arrow">
            <use xlink:href="#mobile-arrow" />
          </svg>
        </button>
        <button class="stickers__btn stickers__btn_right" >
          <svg class="stickers__btn-arrow">
            <use xlink:href="#mobile-arrow" />
          </svg>
        </button>
      </div>
    </div> -->

  <!-- </div> -->
</template>

<script>
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

  export default {
    props: {
      stickerId: String
    },
    data() {
      return {
        currentSticker: null,
        currentStickerIndex: 0,
        totalStickers: 0,
      }
    },
    created() {
      this.currentSticker = this.currentPeriodStickers[this.stickerIndex];
      this.currentStickerIndex = this.currentPeriodStickers.findIndex((item) => item.id === this.currentSticker.id);
      this.totalStickers = this.currentPeriodStickers.length;
    },
    mounted() {
      document.body.style.overflow = 'hidden';
      new Swiper('.swiper-container', {
        initialSlide: this.stickerIndex,
        spaceBetween: -15,
        effect: 'coverflow',
        coverflowEffect: {
          depth: 100,
          rotate: 40,
          slideShadows: false
        },
        pagination: {
          el: '.swiper-pagination',
          dynamicBullets: true
        },
      })
    },
    beforeDestroy() {
      document.body.style.overflow = 'scroll';
    },
    methods: {
      sendGa() {
        window.ga('send',{hitType:'event',eventCategory:'client',eventAction:'click',eventLabel:'video',eventValue:1});
      }
    },
    computed: {
      periodIndex() { 
        return Number(this.stickerId[0]);
      },
      stickerIndex() {
        return Number(this.stickerId[this.stickerId.length - 1]);
      },
      currentPeriodStickers() {
        return this.$store.state.timePeriods[this.periodIndex].stickers;
      }
    }
  }
</script>

<style lang="scss">
.swiper-container {
  background: rgba(5, 5, 5, 0.29);
  z-index: 11;
  position: fixed;
  top: 0;
  left: 0;
  padding-left: 1em;
  width: 100%;
  height: 100vh;
  height: calc(var(--app-height, 1vh) * 100);
  //height: var(--app-height);
}
.swiper-wrapper {
  //margin-top: 4em;
  margin-top: 15vh;
}
.swiper-pagination-bullets {
  bottom: 1vh;
}
.swiper-pagination-bullet {
  width: 18px;
  height: 18px;
  background-color: white;
}

.mobile-stickers {
  width: 21.4375em;
  //height: 77vh;
  height: 30em;
  background: white;
  //padding: 0.1em 1.5em 1.8125em;
  padding: 1em 1em 1em;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  position: relative;

  &__close {
    cursor: pointer;
    width: 1.25em;
    height: 1.25em;
    position: absolute;
    top: 1.25em;
    right: 1.25em;
    z-index: 11;
  }
  &__img {
    display: block;
    margin: 0 auto;
    //width: 13em;
    width: 11em;
    height: 11em;
    margin-bottom: 1px;
    margin-top: -3.75em;
    margin-bottom: 1em;
  }
  &__info {
    width: 100%;
    min-height: 45vh;
    overflow: scroll;
  }
  &__date {
    font-size: 0.625em;
    margin-bottom: 1em;
  }
  &__title {
    font-size: 1.125em;
    margin-bottom: 1em;
    max-width: 14em;
  }
  &__button {
    outline: none;
    border: none;
    color: white;
    font-family: 'Druk Text Wide Cyr';
    font-weight: 800;
    font-size: 0.8em;
    letter-spacing: -0.025em;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 11.25em;
    height: 3.125em;
    background: #000000;
    //box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
    border-radius: 54px;
    margin-top: 1em;
    margin: 1em auto 0em;

    transition: all .3s linear;

    &:hover {
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.55);
    }
  }

  // &__text {
  //   ///////////////////////////margin-bottom: 2em;
  // }
  // &__navigation {
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
    
  //   ///////////////////////////////
  //   display: none;
  // }
  
  &__pagination {
    font-family: 'Druk Text Wide Cyr';
    font-weight: 900;
    font-size: 1.125em;
    letter-spacing: -0.065em;
    color: #1D2126;
  }
  &__btn {
    outline: none;
    border: none;
    overflow: hidden;
    width: 1.93em;
    height: 1.93em;
    background: #000000;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    position: relative;
    margin-right: 0.5em;
    &:last-of-type {
      margin: 0;
    }
    &-arrow {
      width: 1.1em;
      @include abs-center;
    }
    &_left {
      transform: rotate(90deg);
    }
    &_right {
      transform: rotate(-90deg);
    }
  }
}
</style>