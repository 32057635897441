<template>
  <div class="animation">
    <div class="gradient" />
    
    <div class="navigation" @click="nextPeriod">
      <div :class="['navigation__button', {'navigation__button_hover': currentPeriod !== timePeriods.length - 1}]" id="right">
        <svg class="navigation__arrow navigation__arrow_right">
          <use xlink:href="#navigation-btn"/>
        </svg>
      </div>
      <div :class="['navigation__button', {'navigation__button_hover': currentPeriod !== 0}]" id="left">
        <svg class="navigation__arrow navigation__arrow_left">
          <use xlink:href="#navigation-btn"/>
        </svg>
      </div>
    </div>

    <div class="periods">
      <p class="periods__shift">
        <span class="periods__shift-element">2005</span> 
        <span class="periods__shift-element">2006</span> 
      </p>
      <span
        v-for="(period, index) in timePeriods"
        :key="period.name"
        class="periods__item"
        :id="index"
        @click="goToPeriod"
      >
       {{ period.name }}
      </span>
      <p class="periods__shift">
        <span class="periods__shift-element">2022</span> 
        <span class="periods__shift-element">2023</span> 
      </p>
    </div>
    <div class="laptop">
      <img 
        v-for="item in laptops" 
        :src=" require('../assets/images/laptop/' + item + '.png') "
        :key="item"
        class="laptop__item"
      />
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

  export default {
    data() {
      return {
        isScrollActive: false,
        tl: null,
        scrollDirection: 1,
        animationLength: '1600%',
        speed: 0.1,
        laptops: ['laptop0', 'laptop1', 'laptop2', 'laptop3', 'laptop4', 'laptop5', 'laptop6', 'laptop7'],
      }
    },
    mounted() {
      if(this.$store.state.isDesktop) {
        gsap.registerPlugin(ScrollTrigger);
        this.initTl();
        this.createLaptopAnimation();
        this.createPeriodsAnimation();
      }

      window.addEventListener('resize', () => {
        setTimeout(() => {
          document.body.style.overflow = 'hidden';
        }, 400)
      })

      window.addEventListener('keydown', (event) => {
        if(!this.$store.state.isPeriodShown) {
          const direction = event.key;
          const periodIndex = direction === 'ArrowRight' ? this.currentPeriod + 1 : direction === 'ArrowLeft' ? this.currentPeriod - 1 : this.currentPeriod;
          if(periodIndex < 0 || periodIndex > this.timePeriods.length - 1) return;
          this.scrollToPeriod(periodIndex);
        }
      })
    },

    methods: {
      initTl() {
        this.tl = gsap.timeline({
          paused: true,
          ease: 'none',
          scrollTrigger: {
            id: 'trigger',
            trigger: '.animation',
            start: 'top top',
            end: this.animationLength,
            pin: true,
            scrub: this.speed,
            snap: {
              //duration: { min: 0.1, max: 0.4 },
              // snapTo: () => {
              //   return (this.currentPeriod + this.scrollDirection) / 4;
              // },
              snapTo: 1 / 4,
              duration: 0.1,
              ease: 'none',
              //delay: 0.1
            },
            // eslint-disable-next-line no-unused-vars
            onUpdate: ({ progress, direction, isActive }) => {
              this.scrollDirection = direction;
              if(!this.$store.state.isActive) this.$store.commit('SET_ISACTIVE', isActive);

              const currentPeriod = Number(progress.toFixed(2)) * (this.timePeriods.length - 1);
              //Данный toFixed(3) будет выдавать правильный результат при animationLength кратном 4 (periods.length - 1)
              if(Number.isInteger(currentPeriod)) {
                this.sendGa(currentPeriod);
                this.scrollDirection = 0;

                this.$store.commit('SET_CURRENTPERIOD', currentPeriod);
                this.$store.commit('SET_ISACTIVE', false);
              }
            },
          }
        });
      },
      addLaptopToTl(tl, laptopsArr) {
        laptopsArr.forEach((item, index, arr) => {
          if(index !== 0) {
            tl.to(item, {
              display: 'block'
            }, '-=0.001')
          }

          if(index !== arr.length - 1) {
            tl.to(item, {
              display: 'none'
            })
          }
        })
      },
      createLaptopAnimation() {
        const laptopsArr = gsap.utils.toArray('.laptop__item');
        const reversLaptopsArr = gsap.utils.toArray('.laptop__item').reverse();

        this.timePeriods.forEach((item, index, arr) => {
          if(index === arr.length - 1) return
          this.addLaptopToTl(this.tl, laptopsArr);
          this.addLaptopToTl(this.tl, reversLaptopsArr);
        });
      },
      addPeriodsColor(totalDuration) {
        const startColor = this.timePeriods[0].color;
        this.tl.set('.periods__item:nth-of-type(1)', { color: startColor }, 0);
        this.tl.set('.gradient', {background: this.gradient(startColor)}, 0);

        const periodDuration = totalDuration / (this.timePeriods.length - 1);
        this.timePeriods.forEach((item, index, arr) => {
          if(index === arr.length - 1) return
          const shiftIndex = index + 1;

          this.tl.to('.periods__item:nth-of-type(' + shiftIndex + ')', {
            duration: periodDuration,
            ease: 'none',
            color: '#EFEFEF'
          }, periodDuration * index)

          this.tl.to('.periods__item:nth-of-type(' + (shiftIndex + 1) +')', {
            duration: totalDuration / 4,
            ease: 'none',
            color: arr[shiftIndex].color
          }, periodDuration * index)

          this.tl.to('.gradient', {
            duration: totalDuration / 4,
            background: this.gradient(arr[shiftIndex].color),
          }, periodDuration * index)
        })
      },
      createPeriodsAnimation() {
        const totalDuration = this.tl.totalDuration()

        this.tl.to('.periods', {
          duration: totalDuration,
          ease: 'none',
          //xPercent: -80
          xPercent: -56.5
        }, 0)

        this.addPeriodsColor(totalDuration);
      },
      gradient(color) {
        if(navigator.userAgent.indexOf('Firefox') != -1 && parseFloat(navigator.userAgent.substring(navigator.userAgent.indexOf('Firefox') + 8)) >= 3.6) {
          return '';
        } else {
          return 'radial-gradient(50.09% 50.01% at 48.79% 55.65%, ' + color + ' 0%, rgba(255, 255, 255, 0) 100%)';
        }
      },
      sendGa(id) {
        window.ga('send',{hitType:'event',eventCategory:'all',eventAction:'click',eventLabel:`sect${id + 1}`,eventValue:1});
      },
      scrollToPeriod(periodIndex) {
        const scrollHeight = Number(document.querySelector('.pin-spacer').style.padding.split(' ')[2].replace('px', ''));
        gsap.to(window, {
          duration: 1,
          scrollTo: scrollHeight / 4 * periodIndex
        })
      },
      goToPeriod({ target }) {
        this.scrollToPeriod(target.id);
      },
      nextPeriod({ target }) {
        const direction = target.closest('.navigation__button').id;
        const periodIndex = direction === 'right' ? this.currentPeriod + 1 : this.currentPeriod - 1;
        if(periodIndex < 0 || periodIndex > this.timePeriods.length - 1) return;
        this.scrollToPeriod(periodIndex);
      }
    },

    computed: {
      timePeriods() {
        return this.$store.state.timePeriods;
      },
      currentPeriod() {
        return this.$store.state.currentPeriod;
      }
    }
  }
</script>

<style lang="scss" scoped>
.animation {
  position: relative;
  overflow-x: hidden;
  width: 100%;
  //height: 100%;
  min-height: 100vh;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
}
.gradient {
  width: 45em;
  height: 45em;
  transform: rotate(-15deg);

  position: absolute;
  top: 5em;
  left: 8em;
}
.navigation {
  position: fixed;
  top: 0;
  left: 0;
  &__button {
    cursor: default;
    height: 100vh;
    width: 10em;
    position: fixed;
    &:first-of-type {
      right: 0;
      top: 0;
    }
    &:last-of-type {
      left: 0;
      top: 0;
    }
    color: #EFEFEF;
    svg {
      transition: color .3s ease-out;
    }
    &_hover {
      cursor: pointer;
      &:hover {
        svg {
          color: #200E32;
        }
      }
    }
  }
  &__arrow {
    position: absolute;
    top: 50vh;
    transform: translateY(-50%);
    width: 3em;
    height: 2em;
    &_right {
      right: 1.5em;
    }
    &_left {
      left: 1.5em;
      transform: rotate(180deg);
    }
  }
}
.periods {
  display: flex;
  position: absolute;
  top: 9vh;
  left: -8.6em;
  color: #EFEFEF;
  &__item {
    display: inline-block;
    width: 8.5em;
    font-family: 'Druk Text Wide Cyr';
    font-weight: 900;
    font-size: 3em;
    line-height: 100%;
    margin-right: 0.5em;
    letter-spacing: -0.025em;
    color: inherit;
    cursor: pointer;
  }
  &__shift {
    color: #EFEFEF;
    display: inline-block;
    width: 9.5em;
    font-family: 'Druk Text Wide Cyr';
    font-weight: 900;
    font-size: 3em;
    line-height: 100%;
    letter-spacing: -0.025em;
    &-element {
      margin-right: 0.5em;
    }
  }
}
.laptop {
  position: absolute;
  top: 8em;
  //top: 7em;
  top: 26vh;
  left: 15.5em;

  width: 33em;
  height: 22em;
  &__item {
    max-width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;

    display: none;
    &:first-of-type {
      display: block;
    }
  }
}
</style>