/*eslint-disable */
let Share = {
	vkontakte: function(pageUrl, shareTitle, shareText, shareImg) {
		let shareUrl = 'http://vkontakte.ru/share.php?url=';
		shareUrl += pageUrl;
		//const isMobile = document.documentElement.clientWidth < 1024 && document.documentElement.clientWidth < document.documentElement.clientHeight;
		if(true) {
			shareUrl += '&title=' + shareTitle;
			shareUrl += '&description=' + shareText;
			shareUrl += '&image=' + shareImg;
			shareUrl += '&noparse=true';
		}
		Share.popup(shareUrl);
	},
	facebook: function(pageUrl) {
		let shareUrl  = 'https://www.facebook.com/sharer/sharer.php?u=';
		shareUrl += pageUrl;
		Share.popup(shareUrl);
	},
	twitter: function(pageUrl, text) {
		let shareUrl  = 'http://twitter.com/share?';
		shareUrl += 'text=' + text;
		shareUrl += '&url=' + pageUrl;
		shareUrl += '&counturl=' + pageUrl;
		Share.popup(shareUrl);
	},
	popup: function(url) {
		window.open(url,'','toolbar=0,status=0,width=626,height=436');
	}
};
export { Share };
/*eslint-enable */