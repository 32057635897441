import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isDesktop: false,
    appState: 'Home',
    isLoaded: false,
    currentPeriod: 0,
    isModalOpen: false,
    isActive: false,
    isMenuOpen: false,
    isPeriodShown: false,
    timePeriods: [{
        name: '2007–2011',
        color: '#FF6163',
        nameColor: '#F74E50',
        stickers: [{
            id: '0_0',
            name: 'sticker0_0',
            title: 'Основание Авито',
            date: '2007',
            position: {
              top: '2em',
              left: '2em',
              transform: 'rotate(-15deg)'
            },
            text: 'Авито появился в\xa0октябре\xa02007 года, когда\xa0двое предпринимателей из\xa0Швеции — Йонас Нордландер и\xa0Филип Энгельберт — создали онлайн-сервис объявлений. Пользователи могли разместить свой товар на\xa0сайте и\xa0дать ненужной вещи вторую жизнь.'
          },
          {
            id: '0_1',
            name: 'sticker0_1',
            title: 'Запуск сайта',
            date: '2007',
            position: {
              top: '7em',
              left: '8em',
              transform: 'rotate(10deg)'
            },
            text: 'Первая версия сайта запустилась 15\xa0октября\xa02007 года. Разработчики писали её на\xa0PHP — языке программирования с открытым исходным кодом. Изначально проект был монолитным, но\xa0со\xa0временем стал развиваться в\xa0сторону микросервисов. Сейчас в\xa0зависимости от\xa0задач и\xa0нагрузок команда использует Go и\xa0Python.'
          },
          {
            id: '0_2',
            name: 'sticker0_2',
            title: 'Первая команда',
            date: '2009',
            position: {
              top: '9em',
              left: '20em',
              transform: 'rotate(5deg)'
            },
            text: 'Сначала команда Авито была небольшой — всего пара человек. Вместе с\xa0ними работал Дмитрий Ковалёв — будущий вице-президент Авито по\xa0продуктам и\xa0технологиям и\xa0движущая сила AvitoTech. Постепенно штат расширился до\xa010 человек, и\xa0все перебрались в\xa0офис побольше, который находился всего в\xa0нескольких шагах от\xa0старого.'
          },
          {
            id: '0_3',
            name: 'sticker0_3',
            position: {
              top: '3em',
              left: '14em',
              transform: 'rotate(10deg)'
            },
            title: 'Новые сотрудники',
            date: '2009',
            text: 'Летом к\xa0команде присоединился программист Роман Павлушко — будущий руководитель разработки и\xa0СТО проекта. Именно благодаря ему в\xa0Авито появился легендарный холодильник с\xa0нескончаемым Red Bull.'
          },
          {
            id: '0_4',
            name: 'sticker0_4',
            title: 'Первые миллионы',
            date: '2010',
            position: {
              top: '9em',
              left: '2em',
              transform: 'rotate(-5deg)'
            },
            text: 'Авито запустил рекламную кампанию на\xa0телевидении — и\xa0о\xa0портале, где\xa0можно продать и\xa0купить вещи, узнали люди по\xa0всей стране. Многие из\xa0них решили воспользоваться сервисом и\xa0чуть не\xa0обрушили сайт. Тогдашняя версия сайта была рассчитана всего на\xa05 млн пользователей, поэтому\xa0команде пришлось экстренно адаптировать его к\xa0новым нагрузкам.'
          },
          {
            id: '0_5',
            name: 'sticker0_5',
            title: 'Премодерация',
            date: '2010',
            position: {
              top: '2em',
              left: '20em',
              transform: 'rotate(5deg)'
            },
            text: 'Раньше объявления на\xa0Авито проходили только постмодерацию. Когда аудитория сильно выросла, уследить за\xa0продавцами стало сложнее. Например, однажды ленту заспамили розовые трусы, которым разработчики дали говорящее название Famous Ass. Решить проблему помогла премодерация, а\xa0первыми модераторами стали двое специалистов службы поддержки.'
          },
          {
            id: '0_6',
            name: 'sticker0_6',
            date: '2011',
            position: {
              top: '1em',
              left: '8em',
              transform: 'rotate(5deg)'
            },
            title: 'Мобильная версия и\xa0приложение',
            text: 'Авито работал над\xa0мобилизацией продукта сразу по\xa0нескольким фронтам. Техническая команда адаптировала сайт под\xa0мобильную версию, а\xa0разработчики на\xa0аутсорсе создали первое приложение Авито на\xa0iOS.'
          },
          {
            id: '0_7',
            name: 'sticker0_7',
            title: 'Новые сервисы',
            date: '2011',
            position: {
              top: '11em',
              left: '14em',
              transform: 'rotate(-15deg)'
            },
            text: 'Команда интегрировала платежные системы в\xa0сервис и\xa0обновила услугу «Премиум-объявление» — теперь\xa0карточка товара не\xa0только попадала в\xa0блок «Премиум», но\xa0и\xa0поднималась в\xa0поиске. Также\xa0разработчики доработали сам поиск, внедрив фильтрацию объявлений по\xa0станциям метро и\xa0фото.'
          },

        ]
      },
      {
        name: '2012–2014',
        color: '#FFD100',
        nameColor: '#F1C706',
        stickers: [{
            id: '1_0',
            name: 'sticker1_0',
            title: 'Android-версия',
            date: '2012',
            position: {
              top: '2em',
              left: '22em',
              transform: 'rotate(0deg)'
            },
            text: 'В\xa0этом году появилась версия Авито для\xa0Android. Она позволила продавать б/у и\xa0новые товары и\xa0просматривать объявления из\xa0различных категорий еще\xa0большему числу пользователей.'
          },
          {
            id: '1_1',
            name: 'sticker1_1',
            title: 'Первые инвесторы',
            date: '2012',
            position: {
              top: '1em',
              left: '15em',
              transform: 'rotate(-20deg)'
            },
            text: 'Авито попал в\xa0топ-5 самых посещаемых ресурсов рунета и\xa0привлек инвестиции Accel Partners и\xa0Baring Vostok Private Equity Fund. Средства фондов были направлены на\xa0развитие сервиса.'
          },
          {
            id: '1_2',
            name: 'sticker1_2',
            title: 'Новые сервисы',
            date: '2012',
            position: {
              top: '11em',
              left: '8em',
              transform: 'rotate(-10deg)'
            },
            text: 'В\xa0Авито появились разделы «Недвижимость» и\xa0«Авто». Личный кабинет пользователей тоже изменился. Там появился Кошелек с\xa0доступом к\xa0оплате и\xa0историей сделок, совершенных юзером.'
          },
          {
            id: '1_3',
            name: 'sticker1_3',
            title: 'Avito.ma',
            date: '2012',
            position: {
              top: '11em',
              left: '21em',
              transform: 'rotate(0deg)'
            },
            text: 'Авито запустил сайт для\xa0Марокко. Жители страны говорят сразу на\xa0нескольких языках, поэтому разработчики написали внутренний интерфейс для\xa0переводов. Часть команды добавляла описания «фичей» на\xa0рунглише, а\xa0другая переводила их на\xa0арабский и\xa0французский — для местных жителей.'
          },
          {
            id: '1_4',
            name: 'sticker1_4',
            title: 'Новый офис',
            date: '2012',
            position: {
              top: '12em',
              left: '13em',
              transform: 'rotate(5deg)'
            },
            text: 'В\xa0этот год команда Product & Tech сильно выросла, так что в\xa0старом офисе ей\xa0стало тесно. Авито переехал в\xa0здание побольше — рядом со\xa0станцией метро «Белорусская». Вместе с\xa0сотрудниками обживать новое пространство отправились и\xa0вещи из\xa0старого офиса: например, красный торшер, который все прозвали «китайцем», и\xa0пара винтажных кресел.'
          },
          {
            id: '1_5',
            name: 'sticker1_5',
            title: 'Слияние',
            date: '2013',
            position: {
              top: '6em',
              left: '12em',
              transform: 'rotate(-15deg)'
            },
            text: 'Авито и\xa0сервисы объявлений OLX.ru и Slando.ru объединились под\xa0одним брендом — «Авито». После сделки сайт вошёл в\xa0тройку крупнейших онлайн-классифайдов в\xa0мире.'
          },
          {
            id: '1_6',
            name: 'sticker1_6',
            title: 'Новые разделы',
            date: '2013',
            position: {
              top: 'wem',
              left: '8em',
              transform: 'rotate(10deg)'
            },
            text: 'В этом году на сайте запустились новые разделы — «Работа» и «Услуги». Они должны были помочь пользователям быстро и легко найти исполнителя для своей задачи.'
          },
          {
            id: '1_7',
            name: 'sticker1_7',
            title: 'Email-переписка',
            date: '2013',
            position: {
              top: '6em',
              left: '18em',
              transform: 'rotate(-30deg)'
            },
            text: 'На\xa0Авито запустился поиск по\xa0названиям объявлений и\xa0появилась переписка по\xa0email. Теперь, чтобы договориться о\xa0сделке, пользователям стало достаточно написать друг другу по\xa0электронной почте.'
          },
          {
            id: '1_8',
            name: 'sticker1_8',
            title: 'Data Science',
            date: '2013',
            position: {
              top: '2em',
              left: '3em',
              transform: 'rotate(-10deg)'
            },
            text: 'Data Science помог Авито улучшить бизнес-процессы. У истоков машинного обучения стояли Иван Гуз, который автоматизировал модерацию, и\xa0Максим Соколов, занявшийся ценообразованием. Сейчас Machine Learning применяется сразу в\xa0нескольких направлениях: например, распределяет товары по\xa0категориям и\xa0помогает искать спамеров и\xa0мошенников.'
          },
          {
            id: '1_9',
            name: 'sticker1_9',
            title: 'Domofond.ru',
            date: '2014',
            position: {
              top: '7em',
              left: '3em',
              transform: 'rotate(0deg)'
            },
            text: 'В\xa0этом году компания объединилась с\xa0Korbitec, принадлежащей южноафриканскому холдингу Naspers, и\xa0запустила сайт объявлений о\xa0недвижимости Domofond.ru. Этот проект стал первым шагом Авито в\xa0создании крупнейшего портала для\xa0аренды и\xa0продажи жилья в\xa0России.'
          },

        ]
      },
      {
        name: '2015–2016',
        color: '#A169F7',
        nameColor: '#995AFB',
        stickers: [{
            id: '2_0',
            name: 'sticker2_0',
            title: 'Новый вектор',
            date: '2015',
            position: {
              top: '0em',
              left: '21em',
              transform: 'rotate(15deg)'
            },
            text: 'Компания приобрела Checkout — сервис оформления и\xa0доставки заказов. Благодаря сделке Авито получил возможность отправлять и\xa0доставлять товары от\xa0продавцов покупателям.'
          },
          {
            id: '2_1',
            name: 'sticker2_1',
            title: 'Мессенджер',
            date: '2015',
            position: {
              top: '11em',
              left: '14em',
              transform: 'rotate(-15deg)'
            },
            text: 'В\xa0конце года у\xa0Авито появился свой мессенджер. Теперь продавцы и\xa0покупатели могли общаться друг с\xa0другом, не\xa0используя сторонние сервисы. Через пару лет команда переписала его на\xa0Go, но\xa0это уже\xa0другая история :)'
          },
          {
            id: '2_2',
            name: 'sticker2_2',
            title: 'Техпиар',
            date: '2015',
            position: {
              top: '3em',
              left: '2em',
              transform: 'rotate(-10deg)'
            },
            text: 'В\xa0этом году частью команды стал DevRel Михаил Клюев. Он начал активно развивать техпиар — и\xa0уже через год до 90% кандидатов приходили на\xa0собеседования, будучи знакомыми с\xa0брендом работодателя.'
          },
          {
            id: '2_3',
            name: 'sticker2_3',
            title: 'Переезд серверов',
            date: '2016',
            position: {
              top: '7em',
              left: '8em',
              transform: 'rotate(-10deg)'
            },
            text: 'Авито начинался с\xa0пары серверов, которые, по\xa0легенде, разработчики возили в\xa0дата-центры в\xa0багажнике такси. Затем серверы «жили» в\xa0шведском центре Basefarm, а\xa0в\xa02016-м переехали в\xa0московский DataSpace.'
          },
          {
            id: '2_4',
            name: 'sticker2_4',
            title: 'Появление юнитов',
            date: '2016',
            position: {
              top: '0em',
              left: '9em',
              transform: 'rotate(15deg)'
            },
            text: 'В\xa0этом году изменилась структура управления. Функциональные команды стали кросс-функциональными и\xa0объединились в\xa0юниты. За\xa0каждым из\xa0них закрепили собственных разработчиков, аналитиков, дизайнеров и\xa0QA-инженеров, которые отвечали за\xa0развитие определённой части продукта.'
          },
          {
            id: '2_5',
            name: 'sticker2_5',
            title: 'AvitoTech',
            date: '2016',
            position: {
              top: '11em',
              left: '4em',
              transform: 'rotate(0deg)'
            },
            text: 'AvitoTech — это\xa0команда инженеров, которая работает над\xa0продуктом и\xa0постоянно его совершенствует. Фактически она зародилась в\xa0тот\xa0момент, когда появился сам\xa0Авито. Но\xa0официальным днём рождения AvitoTech считается 1\xa0февраля\xa02016 года, когда разработчики начали рассказывать о\xa0технологиях и\xa0инструментах Авито в\xa0своём твиттер-аккаунте.'
          },
          {
            id: '2_6',
            name: 'sticker2_6',
            title: 'UX-лаборатория',
            date: '2016',
            position: {
              top: '12em',
              left: '21em',
              transform: 'rotate(5deg)'
            },
            text: 'В\xa0ноябре в\xa0Авито открылась собственная исследовательская лаборатория. Именно здесь команда изучает поведение пользователей и\xa0тестирует гипотезы, чтобы создавать удобные и\xa0полезные продукты.'
          },
          {
            id: '2_7',
            name: 'sticker2_7',
            title: 'Рейтинг Forbes',
            date: '2016',
            position: {
              top: '3em',
              left: '16em',
              transform: 'rotate(15deg)'
            },
            text: 'Авито занял третье место в\xa0рейтинге самых дорогих компаний рунета по\xa0версии Forbes. Инвесторы прозвали сервис «единорогом» — быстрорастущей компанией с\xa0оценкой выше $1\xa0млрд.'
          },
          {
            id: '2_8',
            name: 'sticker2_8',
            title: 'Покупка Domofond.ru',
            date: '2016',
            position: {
              top: '6em',
              left: '21em',
              transform: 'rotate(10deg)'
            },
            text: 'Авито стал единственным владельцем сайта Domofond.ru. Компания выкупила акции у\xa0соучредителей и\xa0решила развивать сразу два\xa0смежных продукта — собственную «Недвижимость» и Domofond.'
          },
        ]
      },
      {
        name: '2017–2018',
        color: '#97CF26',
        nameColor: '#8AC415',
        stickers: [{
            id: '3_0',
            name: 'sticker3_0',
            title: 'Новый поиск',
            date: '2017',
            position: {
              top: '3em',
              left: '2em',
              transform: 'rotate(0deg)'
            },
            text: 'Сначала на\xa0Авито работала простая фильтрация по\xa0содержимому и\xa0описанию товара, а\xa0объявления ранжировались по\xa0времени размещения. Чтобы усовершенствовать выдачу и\xa0избавить пользователей от\xa0«мусорных» запросов, команда создала релевантный поиск.'
          },
          {
            id: '3_1',
            name: 'sticker3_1',
            title: 'Computer Vision',
            date: '2017',
            position: {
              top: '4em',
              left: '21em',
              transform: 'rotate(-10deg)'
            },
            text: 'В\xa0этом году в\xa0Авито научились определять категорию объявления по\xa0фото с\xa0помощью Computer Vision. Это внутренний датасет и\xa0обученные на\xa0нём нейросети, которые распознают и\xa0сегментируют изображения на\xa0969 классов.'
          },
          {
            id: '3_2',
            name: 'sticker3_2',
            title: 'Развитие приложения',
            date: '2017',
            position: {
              top: '6em',
              left: '18em',
              transform: 'rotate(0deg)'
            },
            text: 'В\xa0приложении Авито заработали новые функции. Пользователям стали доступны поиск по\xa0фото товара, Vision для скрытия лиц и\xa0номеров авто для\xa0объявлений, а\xa0также Voice Over и\xa03D\xa0Touch.'
          },
          {
            id: '3_3',
            name: 'sticker3_3',
            title: 'Публичный профиль',
            date: '2017',
            position: {
              top: '12em',
              left: '21em',
              transform: 'rotate(10deg)'
            },
            text: 'У\xa0продавцов на\xa0сайте появился публичный профиль. Попадая на\xa0него, посетители видят информацию о\xa0пользователе и\xa0могут просмотреть его объявления.'
          },
          {
            id: '3_4',
            name: 'sticker3_4',
            title: 'Новые серверы',
            date: '2017',
            position: {
              top: '0em',
              left: '6em',
              transform: 'rotate(0deg)'
            },
            text: 'В\xa0Авито заработали новые серверы, а\xa0аналитическое хранилище за\xa0год пополнилось на\xa0200 ТВ данных — это\xa0в\xa0несколько раз больше, чем космический телескоп Hubble смог собрать за\xa015 лет работы!'
          },
          {
            id: '3_5',
            name: 'sticker3_5',
            title: 'Новая защита от спама',
            date: '2017',
            position: {
              top: '8em',
              left: '2em',
              transform: 'rotate(0deg)'
            },
            text: 'Чтобы избавить пользователей от\xa0спама, команда Communications запустила анонимный номер. Функция помогла защитить более 80%\xa0новых объявлений в\xa0категориях «Авто» и\xa0«Недвижимость».'
          },
          {
            id: '3_6',
            name: 'sticker3_6',
            title: 'Доставка товаров',
            date: '2018',
            position: {
              top: '8em',
              left: '9em',
              transform: 'rotate(0deg)'
            },
            text: 'На\xa0Авито заработала доставка. Теперь продавцы могут отправлять товары за\xa0пределы своего региона: посылку доставят в\xa0более чем\xa0950 городов и\xa0около 20\xa0тысяч населённых пунктов.'
          },
          {
            id: '3_7',
            name: 'sticker3_7',
            title: 'Бронирование жилья',
            date: '2018',
            position: {
              top: '13em',
              left: '5em',
              transform: 'rotate(0deg)'
            },
            text: 'Перед Чемпионатом мира по\xa0футболу на\xa0Авито появилась краткосрочная аренда жилья в городах, где проходили  матчи. Благодаря этой\xa0функции болельщики могли найти и\xa0забронировать жилье непосредственно перед игрой.'
          },
          {
            id: '3_8',
            name: 'sticker3_8',
            title: 'Сайт AvitoTech',
            date: '2018',
            position: {
              top: '0em',
              left: '17em',
              transform: 'rotate(0deg)'
            },
            text: 'Раньше AvitoTech вёл твиттер и\xa0блог на\xa0Хабре, но\xa0в августе запустил и\xa0собственный сайт с\xa0контентом для\xa0разработчиков. Именно здесь публикуются материалы про\xa0технологии и\xa0внутренние процессы, а\xa0также свежие вакансии и\xa0афиша мероприятий.'
          },
          {
            id: '3_9',
            name: 'sticker3_9',
            title: 'Рост сервисов',
            date: '2018',
            position: {
              top: '11em',
              left: '15em',
              transform: 'rotate(10deg)'
            },
            text: 'В\xa0этом году количество сервисов превысило 900. Они работают с\xa0более чем 400 базами данных (PostgreSQL, Redis, Tarantool, MongoDB), развёрнутыми на\xa0500\xa0хостах. Чтобы собрать бизнес-метрики всех этих сервисов, специалисты AvitoTech создали и\xa0пересоздали более 200\xa0аналитических витрин.'
          },
          {
            id: '3_10',
            name: 'sticker3_10',
            title: 'Отзывы',
            date: '2018',
            position: {
              top: '2em',
              left: '11em',
              transform: 'rotate(0deg)'
            },
            text: 'На\xa0Авито заработали отзывы. Пользователи могут поставить оценку продавцу и\xa0рассказать остальным покупателям о\xa0своём опыте: например, соответствовал ли товар описанию и\xa0легко ли было его получить. Такая обратная связь формирует рейтинг продавца и\xa0помогает другим пользователям понять, стоит ли заключать с\xa0ним сделку.'
          },
        ]
      },
      {
        name: '2019–2021',
        color: '#00AAFF',
        nameColor: '#00A1F1',
        stickers: [{
            id: '4_0',
            name: 'sticker4_0',
            title: 'Рост команды',
            date: '2019',
            position: {
              top: '9em',
              left: '21em',
              transform: 'rotate(0deg)'
            },
            text: 'В\xa0Авито работает уже 2000 человек. Сердце компании — платформенные и\xa0продуктовые команды Product & Tech, которые занимаются разработкой и\xa0поддержкой вертикалей General & Marketplace, «Авто», «Недвижимость», «Услуги» и\xa0«Работа».'
          },
          {
            id: '4_1',
            name: 'sticker4_1',
            title: 'Персонализация',
            date: '2019',
            position: {
              top: '2em',
              left: '11em',
              transform: 'rotate(0deg)'
            },
            text: 'Авито запустил персонализацию главной страницы, работающую на\xa0основе нейронных сетей. Когда пользователи попадают на\xa0сайт, то видят не\xa0рандомные подборки товаров, а\xa0объявления, отвечающие их\xa0интересам.'
          },
          {
            id: '4_2',
            name: 'sticker4_2',
            title: 'Расширенная доставка',
            date: '2019',
            position: {
              top: '7em',
              left: '15em',
              transform: 'rotate(0deg)'
            },
            text: 'Чтобы упростить сделки между продавцами и\xa0покупателями, Авито начал сотрудничать с\xa0Почтой России. Такое партнёрство позволило доставлять товары в\xa0самые удалённые уголки страны. Чтобы воспользоваться услугой, продавцу достаточно прийти в\xa0любое отделение почтовой связи.'
          },
          {
            id: '4_3',
            name: 'sticker4_3',
            title: 'Авито Pro',
            date: '2020',
            position: {
              top: '11em',
              left: '12em',
              transform: 'rotate(10deg)'
            },
            text: 'Компания запустила Авито Pro — кабинет для управления объявлениями и\xa0их продвижением. Он\xa0создан, чтобы помочь бизнесу усовершенствовать рутинные процессы, упростить сбор аналитики и\xa0грамотно оценивать эффективность своих вложений. Доступ к\xa0нему можно получить автоматически — при покупке тарифов «Базовый», «Расширенный» или «Максимальный».'
          },
          {
            id: '4_4',
            name: 'sticker4_4',
            title: 'Бот Авито Работа',
            date: '2020',
            position: {
              top: '8em',
              left: '6em',
              transform: 'rotate(0deg)'
            },
            text: 'На\xa0Авито ищут работу люди самых разных профессий: от\xa0курьеров до\xa0строителей. Чтобы упростить поиск, в\xa0декабре команда «Работы» запустила чат-бота, который помогает находить вакансии.'
          },
          {
            id: '4_5',
            name: 'sticker4_5',
            title: 'Бизнес-профиль',
            date: '2020',
            position: {
              top: '11em',
              left: '1em',
              transform: 'rotate(0deg)'
            },
            text: 'Команда обновила профиль для\xa0пользователей, у\xa0которых есть свой бизнес. На\xa0странице можно разместить информацию о\xa0компании, рассказать об\xa0акциях и\xa0поделиться отзывами покупателей.'
          },
          {
            id: '4_6',
            name: 'sticker4_6',
            title: 'Покупка GigAnt',
            date: '2020',
            position: {
              top: '2em',
              left: '20em',
              transform: 'rotate(0deg)'
            },
            text: 'Авито купил 25% в\xa0сервисе для поиска временных работников GigAnt. Он станет частью «Работы» и\xa0поможет пользователям находить подрядчиков для\xa0небольших задач.'
          },
          {
            id: '4_7',
            name: 'sticker4_7',
            title: 'Юбилей AvitoTech',
            date: '2021',
            position: {
              top: '2em',
              left: '3em',
              transform: 'rotate(-5deg)'
            },
            text: 'Пять лет пролетели как мгновение! Сейчас в\xa0техническом департаменте работают более 800\xa0инженеров, которые поддерживают и\xa0развивают сервисы Авито. Посмотрите на\xa0историю компании глазами сотрудников AvitoTech и\xa0узнайте больше про\xa0наши продукты, технологии и\xa0корпоративную культуру.'
          },
        ]
      }
    ]
  },
  mutations: {
    SET_ISDESKTOP(state, newValue) {
      state.isDesktop = newValue;
    },
    SET_APPSTATE(state, newAppState) {
      state.appState = newAppState;
    },
    SET_ISLOADED(state, newIsLoaded) {
      state.isLoaded = newIsLoaded;
    },
    SET_CURRENTPERIOD(state, newPeriod) {
      state.currentPeriod = newPeriod;
    },
    SET_ISMODALOPEN(state, newValue) {
      state.isModalOpen = newValue;
    },
    SET_ISACTIVE(state, newIsActive) {
      state.isActive = newIsActive;
    },
    SET_ISMENUOPEN(state, newValue) {
      state.isMenuOpen = newValue;
    },
    SET_ISPERIODSHOWN(state, newValue) {
      state.isPeriodShown = newValue;
    }
  },
  getters: {
    currentPeriodData(state) {
      return state.timePeriods[state.currentPeriod]
    },
    currentPeriodStickers(state, getters) {
      return getters.currentPeriodData.stickers;
    }
  },
  // actions: {
  // },
  modules: {}
})