<template>
  <div class="stickers">
    <img 
      v-for="item in currentPeriodStickers"
      :key="item.id"
      :id="item.id"
      :src=" require('../assets/images/stickers/' + item.name + '.png') " 
      class="stickers__item"
      :style=" item.position "
      @click="$emit('setSticker', $event.target.id); sendGa($event.target.id)"
    >
  </div>
</template>

<script>
import { gsap } from 'gsap';
  export default {
    watch: {
      isActive(newValue) {
        newValue ? this.hideStickers() : this.showStickers();
      },
      isModalOpen() {
        this.showStickers();
        this.hoverStickerAnimation();
      }
    },
    data() {
      return {
        appearDuration: 1.8,
        hideDuration: 0.3,
        perspective: 350,
        showTl: null
      }
    },
    mounted() {
      if(!this.isModalOpen) {
        this.showStickers();
        this.hoverStickerAnimation();
      }
    },
    methods: {
      showStickers() {
        this.showTl = gsap.timeline();
        this.showTl
          .set('.stickers__item', { transformPerspective: this.perspective })
          .fromTo('.stickers__item', {
            //autoAlpha: 0,
            z: 500
          }, {
            z: 0,
            autoAlpha: 1,
            ease: 'power1.in',
            stagger: {
              from: 'random',
              amount: this.appearDuration
            },
          })
      },
      hideStickers() {
        this.showTl.pause();
        gsap.to('.stickers__item', {
          autoAlpha: 0,
          z: 50,
          duration: this.hideDuration
        })
      },
      hoverStickerAnimation() {
        const stickers = gsap.utils.toArray('.stickers__item');
        stickers.forEach(item => {
          const hoverEffect = gsap.to(item, {
            duration: this.hideDuration,
            scale: 1.1,
            //rotate: 'random(-10, 10, 2)',
            rotate: 0,
            paused: true,
            zIndex: 2,
            filter: 'drop-shadow(0px 4px 11px rgba(0, 0, 0, 0.41))'
          })
          item.addEventListener('mouseenter', () => hoverEffect.play())
          item.addEventListener('mouseleave', () => hoverEffect.reverse())
        })
      },
      sendGa(id) {
        window.ga('send',{hitType:'event',eventCategory:'all',eventAction:'click',eventLabel:'stickers',eventValue:1});
        window.ga('send',{hitType:'event',eventCategory:'all',eventAction:'click',eventLabel:'bigstickers',eventValue:1});
        this.gaInfo.forEach(item => {
          if(item.stickers.includes(id)) {
            window.ga('send',{hitType:'event',eventCategory:'laptop',eventAction:'click',eventLabel:item.event,eventValue:1});
          }
        })
      }
    },
    computed: {
      currentPeriodStickers() {
        return this.$store.getters.currentPeriodStickers;
      },
      isActive() {
        return this.$store.state.isActive;
      },
      isModalOpen() {
        return this.$store.state.isModalOpen;
      },
      currentPeriod() {
        return this.$store.state.currentPeriod;
      },
      gaInfo() {
        const years = ['2007', '2012', '2015', '2017', '2019'];
        return [
          {
            stickers: ['0_0', '0_2', '1_2', '1_5', '1_8', '2_0', '2_4', '2_8', '3_1', '3_4', '4_0', '4_1', '4_5', '4_7'],
            event: years[this.currentPeriod] + '_ill1'
          },
          {
            stickers: ['0_1', '0_5', '0_7', '1_0', '1_4', '1_6', '2_2', '2_5', '2_6', '3_2', '3_5', '3_7', '3_9', '3_10', '4_2', '4_4'],
            event: years[this.currentPeriod] + '_ill2'
          },
          {
            stickers: ['0_3', '0_4', '0_6', '1_1', '1_3', '1_7', '1_9', '2_1', '2_3', '2_7', '3_0', '3_3', '3_6', '3_8', '4_3', '4_6'],
            event: years[this.currentPeriod] + '_ill3'
          },
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>
.stickers {
  position: fixed;
  //top: 7.2em;
  top: 24vh;
  left: 16.5em;
  width: 31em;
  height: 21.5em;

  &__item {
    //width: 11.6em;
    width: 8em;
    position: absolute;
    cursor: pointer;
    opacity: 0;

    // &:nth-of-type(1) {
    //   top: 1em;
    //   left: 1em;
    // }
    // &:nth-of-type(2) {
    //   top: 1em;
    //   left: 8em;
    // }
    // &:nth-of-type(3) {
    //   top: 2em;
    //   left: 13em;
    // }
    // &:nth-of-type(4) {
    //   top: 1em;
    //   left: 19em;
    // }
    // &:nth-of-type(5) {
    //   top: 9em;
    //   left: 1em;
    // }
    // &:nth-of-type(6) {
    //   top: 9em;
    //   left: 8em;
    // }
    // &:nth-of-type(7) {
    //   top: 9em;
    //   left: 13em;
    // }
    // &:nth-of-type(8) {
    //   top: 9em;
    //   left: 19em;
    // }
  }
}
</style>