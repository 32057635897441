<template>
  <div class="footer">
    <div class="gradients footer__gradients">
      <div class="gradients__blue" />
      <div class="gradients__yellow" />
      <div class="gradients__ocean" />
    </div>

    <Share :footer="true" />

    <h2 class="footer__sgm">
      &copy; 2021<br>
      Sorry, Guys. Media
    </h2>
    <button class="footer__btn" @click="scrollTop">
      <svg class="footer__btn-arrow">
        <use xlink:href="#mobile-arrow" />
      </svg>
    </button>
  </div>
</template>

<script>
import Share from './Share';
import { gsap } from 'gsap';
  export default {
    components: {
      Share
    },
    methods: {
      scrollTop() {
        gsap.to(window, {
          duration: 1,
          scrollTo: 0
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  //height: 100vh;
  //height: calc(var(--vh, 1vh) * 100);
  position: relative;
  overflow: hidden;
  padding: 8.875em 1em 0;
  padding-top: 20vh;
  &__sgm {
    font-size: 1em;
    text-transform: uppercase;
    position: absolute;
    left: 1em;
    bottom: 1.25em;
  }
  &__btn {
    display: block;
    width: 3.125em;
    height: 3.125em;
    background: black;
    cursor: pointer;
    border-radius: 50%;
    outline: none;
    border: none;
    transform: rotate(180deg);

    position: absolute;
    right: 1em;
    bottom: 1.437em;
    &-arrow {
      @include abs-center;
      width: 1.3em;
    }
  }
}
.footer__gradients {
  opacity: 1;
}
.gradients {
  display: none;

  &__blue {
    position: absolute;
    top: -11em;
    left: -10.5em;
    width: 25em;
    height: 16.3em;
    background: radial-gradient(50% 50% at 50% 50%, #B399FD 0%, rgba(196, 196, 196, 0) 100%);
    transform: rotate(-16.62deg);
  }
  &__yellow {
    position: absolute;
    bottom: -12em;
    left: -11em;
    width: 22.68em;
    height: 19.68em;
    background: radial-gradient(50% 50% at 50% 50%, #FAD62A 0%, rgba(196, 196, 196, 0) 100%);
    transform: rotate(-18.34deg);
  }
  &__ocean {
    position: absolute;
    bottom: 1em;
    right: -5.75em;
    width: 14.1em;
    height: 12.375em;
    background: radial-gradient(50% 50% at 50% 50%, #48F0F4 0%, rgba(196, 196, 196, 0) 100%);
    transform: rotate(3.48deg);
  }
  @include mobile {
    display: block;
  }
}
</style>