<template>
  <transition name="fade">
    <div class="modal" v-if="isModalOpen" ref="modal">
      <div class="modal__card">
        <div class="modal__item">
          <div class="modal__image-container">
            <svg class="modal__image-click">
              <use xlink:href="#click" v-if="isDesktop" />
              <g v-else>
                <g fill="#000" clip-path="url(#clip0)">
                <path d="M46.75 19.852l-8.545-9.39c-1.691-1.691-4.445-1.694-6.152.013-.541.541-.91 1.189-1.1 1.88a4.353 4.353 0 00-6.82 2.27 4.355 4.355 0 00-6.808 2.23l-4.027-4.028c-1.69-1.69-4.445-1.693-6.152.014a4.344 4.344 0 000 6.139l15.473 15.472-9.178 1.441a5.09 5.09 0 00-4.35 5.016c0 1.88 1.53 3.41 3.41 3.41H33.93c3.339 0 6.477-1.3 8.839-3.662l3.575-3.575A12.396 12.396 0 0050 28.255c0-3.114-1.154-6.098-3.25-8.403zm-2.013 15.623l-3.575 3.575a10.16 10.16 0 01-7.232 2.995H12.5c-.627 0-1.136-.509-1.136-1.136a2.812 2.812 0 012.416-2.768l11.397-1.791c.423-.066.77-.364.905-.77a1.137 1.137 0 00-.277-1.155L8.753 17.373a2.079 2.079 0 012.938-2.939l11.37 11.37a1.137 1.137 0 101.605-1.608l-4.643-4.644a2.074 2.074 0 01.014-2.938 2.074 2.074 0 012.925 0l4.645 4.645a1.137 1.137 0 101.605-1.609l-2.37-2.37a2.074 2.074 0 01.013-2.94 2.074 2.074 0 012.925 0l2.382 2.383c.005.002.002.002.002.002l.002.002.003.002.002.003h.002a1.135 1.135 0 001.586-.02 1.137 1.137 0 000-1.608l-.097-.097a2.058 2.058 0 01-.607-1.464c0-.552.213-1.07.618-1.475.81-.805 2.118-.802 2.889-.039l8.509 9.353a10.205 10.205 0 012.657 6.873c0 2.727-1.062 5.29-2.991 7.22z"/>
                <path d="M19.536 11.711c-1.654-3.66-5.309-6.03-9.309-6.03C4.59 5.682 0 10.272 0 15.91c0 4 2.368 7.655 6.03 9.312a1.142 1.142 0 001.504-.569 1.14 1.14 0 00-.568-1.504c-2.85-1.289-4.693-4.13-4.693-7.239 0-4.386 3.568-7.955 7.954-7.955 3.11 0 5.95 1.844 7.24 4.694a1.136 1.136 0 102.07-.936z"/>
                </g>
                <defs>
                  <clipPath id="clip0">
                    <path fill="#fff" d="M0 0h50v50H0z"/>
                  </clipPath>
                </defs>
              </g>
            </svg>
          </div>
          <p class="modal__text">
            <strong>{{ isDesktop ? 'Кликайте' : 'Тапайте' }}</strong> по стикерам, чтобы поближе познакомиться с{{'\xa0'}}AvitoTech!
          </p>
        </div>
        <div class="modal__item">
          <div class="modal__image-container">
            <svg class="modal__image-scroll">
              <use xlink:href="#scroll" v-if="isDesktop" />
              <use xlink:href="#mobile-scroll" v-else />
            </svg>
          </div>
          <p class="modal__text">
            <span v-if="isDesktop"><strong>Кликайте</strong> по датам, чтобы перемещаться во{{'\xa0'}}времени.</span>
            <span v-else><strong>Скролльте</strong> годы, чтобы перемещаться во{{'\xa0'}}времени.</span>
          </p>
        </div>
        <button class="modal__button" @click="closeModal">{{ isDesktop ? 'На старт!' : 'Поехали!' }}</button>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    mounted() {
      //ставим setTiomeout, так как scrollTrigger перезаписывает это свойство
      if(this.isDesktop) {
        setTimeout(() => {
          document.body.style.overflow = 'hidden';
        }, 10)
      }
    },
    methods: {
      closeModal() {
        this.sendGa();
        !this.isDesktop ? document.body.style.overflow = 'scroll' : 0;
        //document.body.style.overflow = 'scroll';
        this.$store.commit('SET_ISMODALOPEN', false);
      },
      sendGa() {
        window.ga('send',{hitType:'event',eventCategory:'popup',eventAction:'click',eventLabel:'start',eventValue:1});
      }
    },
    computed: {
      isModalOpen() {
        return this.$store.state.isModalOpen;
      },
      isDesktop() {
        return this.$store.state.isDesktop;
      }
    }
  }
</script>

<style lang="scss" scoped>
.modal {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;
  &__card {
    width: 22.63em;
    //height: 15.63em;
    background: white;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    padding: 2.13em 1.53em 1.93em;

    position: absolute;
    //top: 9em;
    top: 30vh;
    left: 20.5em;

    @include mobile {
      width: 21.5em;
      height: auto;
      top: 30vh;
      left: 1em;
      padding: 1.875em 1.2em 3em;
    }
  }
  &__item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 0.6em;
    &:last-of-type {
      margin-bottom: 1.5em;
    }
    @include mobile {
      margin-bottom: 1.56em;
      &:last-of-type {
        margin-bottom: 2em;
      }
    }
  }
  &__image-container {
    flex-shrink: 0;
    width: 2.5em;
    height: 2.5em;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 1em;
    @include mobile {
      width: 3.125em;
      height: 3.125em;
    }
  }
  &__image-click {
    width: 100%;
    height: 100%;
    @include mobile {
      width: 3.125em;
      height: 3.125em;
    }
  }
  &__image-scroll {
    width: 100%;
    height: 100%;
    @include mobile {
      width: 3.125em;
      height: 3.125em;
    }
  }
  &__text {
    font-size: 1.125em;
  }
  &__button {
    outline: none;
    border: none;
    color: white;
    font-family: 'Druk Text Wide Cyr';
    font-weight: 800;
    font-size: 0.6em;
    cursor: pointer;

    display: block;
    margin: 0 auto;
    width: 13.7em;
    height: 3.61em;
    background: #000000;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
    border-radius: 54px;

    transition: all .3s linear;

    @include mobile {
      font-size: 1em;
      width: 11.25em;
      height: 3.125em;
    }

    &:hover {
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.55);
    }
  }
}
</style>