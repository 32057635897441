<template>
  <div class="home">
    <Preloader class="home__preloader" ref="preloader" />
    <div class="home__content" ref="content">
      <div class="home__info">
        <h1 class="home__title">AvitoTech исполнилось 5 лет!</h1>
        <p class="main home__text">AvitoTech — это команда инженеров, которая  постоянно совершенствует сервисы Авито. Благодаря её усилиям небольшой стартап стал одним из{{'\xa0'}}лидеров рунета. {{ isDesktop ? 'Кликайте' : 'Тапайте' }} по{{'\xa0'}}стикерам, чтобы узнать его историю.</p>
      </div>

      <button v-if="isDesktop" class="home__button" ref="button" @click="$store.commit('SET_APPSTATE', 'Main'); sendGa()">
        <svg class="home__button-arrow">
          <use xlink:href="#arrow" />
        </svg>
        <svg class="home__button-go">
          <use xlink:href="#go" />
        </svg>
      </button>
    </div>

    <button v-if="!isDesktop" class="home__button_mobile" ref="button" @click="scrollDown">
      <svg class="home__button_mobile-arrow">
        <use xlink:href="#mobile-arrow" />
      </svg>
    </button>
    <div v-if="!isDesktop" class="gradients" ref="gradients">
      <div class="gradients__blue" />
      <div class="gradients__yellow" />
      <div class="gradients__ocean" />
    </div>
  </div>
</template>

<script>
import Preloader from './Preloader.vue';
import { gsap } from 'gsap';
  export default {
    name: 'Home',
    components: {
      Preloader
    },
    watch: {
      isLoaded() {
        document.body.style.overflow = 'scroll';
        const tl = gsap.timeline();
        tl
          .to(this.$refs.preloader.$el, {
            duration: 1,
            yPercent: this.isDesktop ? -50 : -70
          })
          .fromTo(this.$refs.content, {
            opacity: 0,
            y: this.isDesktop ? -50 : -10
          }, {
            duration: 1,
            opacity: 1,
            y: 0
          })
          .fromTo(this.$refs.button, {
            autoAlpha: 0,
            scale: 1.3
          }, {
            duration: 1,
            autoAlpha: 1,
            scale: 1
          }, '-=0.5')

        if(!this.isDesktop) {
          tl.to(this.$refs.gradients, {
            duration: 0.5,
            opacity: 1
          }, '-=1')
        }
      }
    },
    mounted() {
      if(!this.isDesktop) {
        document.documentElement.style.setProperty('--main-height', `${window.innerHeight * 0.01}px`);
        const appHeight = () => document.documentElement.style.setProperty('--app-height', `${window.innerHeight * 0.01}px`);
        window.addEventListener('resize', appHeight)
        appHeight();
      }
      document.body.style.overflow = 'hidden';
    },
    methods: {
      scrollDown() {
        this.sendGa();
        gsap.to(window, {
          duration: 1,
          scrollTo: '#period0'
        })
      },
      sendGa() {
        window.ga('send',{hitType:'event',eventCategory:'main',eventAction:'click',eventLabel:'start_main',eventValue:1});
      }
    },
    computed: {
      isLoaded() {
        return this.$store.state.isLoaded;
      },
      isDesktop() {
        return this.$store.state.isDesktop;
      }
    }
  }
</script>

<style lang="scss" scoped>
@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.home {
  overflow: hidden;
  position: relative;
  width: 100%;
  //min-height: 100vh;
  height: 100vh;
  height: calc(var(--main-height, 1vh) * 100);
  &__preloader {
    position: absolute;
    //top: 10em;
    top: 32vh;
    left: 6em;
    @include mobile {
      //top: 15em;
      top: 35vh;
      left: 1em;
    }
  }
  &__content {
    opacity: 0;
    //margin-top: 18.5em;
    margin-top: 57vh;

    padding-right: 9em;
    padding-left: 6.73em;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mobile {
      position: static;
      display: block;
      //margin-top: 17em;
      margin-top: 42vh;
      padding-right: 1.125em;
      padding-left: 1.125em;
    }
  }
  &__info {
    max-width: 34em;
  }
  &__title {
    margin-bottom: 0.3em;
    @include mobile {
      margin-bottom: 0.8em;
    }
  }
  &__button {
    perspective: 500;
    width: 7.04em;
    height: 7.04em;
    background: black;
    cursor: pointer;
    border-radius: 50%;
    position: relative;
    outline: none;
    border: none;
    transition: all .3s linear;

    @include mobile {
      display: none;
    }

    &-arrow {
      width: 2em;
      @include abs-center;
    }
    &-go {
      width: 6.42em;
      height: 6.42em;
      @include abs-center;
      animation: spin 8s infinite linear;
    }

    &:hover {
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.55);
    }
  }
  &__button_mobile {
    display: none;

    opacity: 0;
    perspective: 500;
    width: 3.125em;
    height: 3.125em;
    background: black;
    cursor: pointer;
    border-radius: 50%;
    outline: none;
    border: none;

    position: absolute;
    left: 1.125em;
    //bottom: 4.437em;
    bottom: 3vh;
    &-arrow {
      @include abs-center;
      width: 1.3em;
    }

    @include mobile {
      display: block;
    }
  }
}
.gradients {
  display: none;

  &__blue {
    position: absolute;
    top: -12em;
    left: -11.5em;
    width: 25em;
    height: 16.3em;
    background: radial-gradient(50% 50% at 50% 50%, #B399FD 0%, rgba(196, 196, 196, 0) 100%);
    transform: rotate(-16.62deg);
  }
  &__yellow {
    position: absolute;
    bottom: -17em;
    left: -2.8em;
    width: 22.68em;
    height: 19.68em;
    background: radial-gradient(50% 50% at 50% 50%, #FAD62A 0%, rgba(196, 196, 196, 0) 100%);
    transform: rotate(-18.34deg);
  }
  &__ocean {
    position: absolute;
    bottom: -6.3em;
    right: -8.75em;
    width: 14.1em;
    height: 12.375em;
    background: radial-gradient(50% 50% at 50% 50%, #48F0F4 0%, rgba(196, 196, 196, 0) 100%);
    transform: rotate(3.48deg);
  }

  @include mobile {
    display: block;
  }
}
</style>