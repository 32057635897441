<template>
  <div class="main">
    <Modal />
    <Animation />
    <Stickers :key="currentPeriod" @setSticker="setStickerId" />
    <Period :stickerId="stickerId" v-if="isPeriodShown" @goBack="goBack" />
    <SideMenu />
  </div>
</template>

<script>
import Animation from './Animation';
import Modal from './Modal';
import Stickers from './Stickers';
import Period from './Period';
import SideMenu from './SideMenu';
  export default {
    name: 'Main',
    data() {
      return {
        stickerId: 0,
        //isPeriodShown: false
      }
    },
    created() {
      this.$store.commit('SET_ISMODALOPEN', true);
      this.$store.commit('SET_ISPERIODSHOWN', false);
    },
    // mounted() {
    //   setTimeout(() => {
    //     document.body.style.overflow = 'hidden';
    //   }, 10)
    // },
    components: {
      Animation,
      Modal,
      Stickers,
      Period,
      SideMenu
    },
    methods: {
      setStickerId(stickerId) {
        this.stickerId = stickerId;
        this.$store.commit('SET_ISPERIODSHOWN', !this.isPeriodShown);
        //this.isPeriodShown = !this.isPeriodShown;
      },
      goBack() {
        this.$store.commit('SET_ISPERIODSHOWN', !this.isPeriodShown);
      }
    },
    computed: {
      currentPeriod() {
        return this.$store.state.currentPeriod;
      },
      currentPeriodColor() {
        return this.$store.getters.currentPeriodData.color;
      },
      isPeriodShown() {
        return this.$store.state.isPeriodShown;
      }
    }
  }
</script>

<style lang="scss" scoped>
.transition {
  width: 12px;
  height: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  opacity: 0;
}
.main {
  position: relative;
  width: 100%;
  //height: 100%;
  min-height: 100vh;
  margin: 0;
}
</style>
