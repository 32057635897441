var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"transition",style:('background:' + _vm.currentPeriodData.color)}),_c('div',{ref:"periodBg",staticClass:"period",style:('background:' + _vm.currentPeriodData.color)},[_c('div',{staticClass:"period__info"},[_c('img',{staticClass:"period__sticker",attrs:{"src":require('../assets/images/stickers/' + _vm.currentSticker.name + '.png')}}),_c('div',{staticClass:"period__text"},[_c('h2',{staticClass:"period__date",style:('color:' +  _vm.currentPeriodData.nameColor),domProps:{"innerHTML":_vm._s(_vm.currentSticker.date)}}),_c('h2',{staticClass:"period__title",domProps:{"innerHTML":_vm._s(_vm.currentSticker.title)}}),_c('p',{staticClass:"period__text",domProps:{"innerHTML":_vm._s(_vm.currentSticker.text)}}),(_vm.currentSticker.id === '4_7')?_c('a',{staticClass:"period__button",attrs:{"href":"https://youtu.be/tvaRBrS14rE","target":"_blank"},on:{"click":function($event){return _vm.sendGa('button')}}},[_vm._v(" Гоу на Ютуб ")]):_vm._e()])]),_c('div',{staticClass:"period__pagination"},_vm._l((_vm.currentPeriodStickers),function(item){return _c('img',{key:item.name,class:['period__pagination-item', { 'period__pagination-item-active': item.id === _vm.currentSticker.id }],attrs:{"src":require('../assets/images/stickers/' + item.name + '.png'),"id":item.id},on:{"click":_vm.setSticker}})}),0),_c('button',{staticClass:"period__back",on:{"click":_vm.goBack}},[_c('svg',{staticClass:"period__arrow"},[_c('use',{attrs:{"xlink:href":"#back-arrow"}})]),_vm._v(" Назад ")]),_c('span',{class:['period__name', {'period__name_hover': _vm.currentPeriod !== 0}],style:('color:' + _vm.currentPeriodData.nameColor),attrs:{"id":"prev"},on:{"click":function (ref) {
	var target = ref.target;

	return _vm.changePeriod(target.id);
}}},[_vm._v(" "+_vm._s(_vm.periodName('prev'))+" ")]),_c('span',{class:['period__name', {'period__name_hover': _vm.currentPeriod !== _vm.timePeriods.length - 1}],style:('color:' + _vm.currentPeriodData.nameColor),attrs:{"id":"next"},on:{"click":function (ref) {
	var target = ref.target;

	return _vm.changePeriod(target.id);
}}},[_vm._v(" "+_vm._s(_vm.periodName('next'))+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }