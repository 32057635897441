<template>
  <div class="preloader" ref=numbers>
    <span class="number" :style=" 'color:' + colors[0]">{{ Math.floor(animatedYear / 1000) % 10 }}</span>
    <span class="number" :style=" 'color:' + colors[1]">{{ Math.floor(animatedYear / 100) % 10 }}</span>
    <span class="number" ref="preLastNumber" :style=" 'color:' + colors[2]">{{ Math.floor(animatedYear / 10) % 10 }}</span>
    <span class="number" ref="lastNumber" :style=" 'color:' + colors[3]">{{ Math.floor(animatedYear) % 10 }}</span>
  </div>
</template>

<script>
import { gsap } from 'gsap';
  export default {
    name: 'Preloader',
    watch: {
      year(newValue) {
        //let prevAnimatedYear = this.animatedYear;
        let prevAnimatedYear = 2007;
        let currentIndex = 0;
        gsap.to(this.$data, {
          animatedYear: newValue,
          duration: 1,
          ease: 'none',
          onUpdate: () => {
            if(prevAnimatedYear != Math.floor(this.animatedYear)) {
              this.$refs.lastNumber.style.color = this.colors[ currentIndex ];

              if(Math.floor(prevAnimatedYear / 10) % 10 != Math.floor(this.animatedYear / 10) % 10) {
                this.$refs.preLastNumber.style.color = this.colors[ currentIndex + 2 ];
              }
              prevAnimatedYear++;
              currentIndex < this.colors.length - 1 ? currentIndex++ : currentIndex = 0;
            }
          }
        })
      },
      dataReady() {
        this.$store.commit('SET_ISLOADED', true);

        const stickers = [];
        this.timePeriods.forEach((period, index) => {
          if(index === 0) return;
          period.stickers.forEach(item => {
            const stickerUrl = require('../assets/images/stickers/' + item.name + '.png');
            stickers.push(stickerUrl);
          })
        })

        this.$preloadImages(stickers);
      }
    },
    data() {
      return {
        year: 2007,
        animatedYear: 2007,
        colors: ['#FF6163', '#FFD100', '#A169F7', '#97CF26', '#00AAFF'],
        imagesReady: false,
        fontsReady: false
      }
    },
    mounted() {
      this.preloadImages();
      this.preloadFonts();
    },
    methods: {
      preloadImages() {
        const startImages = [];
        for(let i = 0; i < 8; i++) {
          const imageUrl = require('../assets/images/laptop/laptop' + i + '.png');
          startImages.push(imageUrl);
        }
        this.satrtStickers.forEach(item => {
          const stickerUrl = require('../assets/images/stickers/' + item.name + '.png');
          startImages.push(stickerUrl);
        })
        this.$preloadImages(startImages).then(() => {
          this.year += 7;
          setTimeout(() => {
            this.imagesReady = true;
          }, 1000)
        })
      },
      preloadFonts() {
        const options = {
          custom: {
            families: ['Druk Text Wide Cyr:n8,n9', 'Lato:n4,n5,n7,n9']
          }
        }
        this.$preloadFonts(options).then(() => {
          this.year += 7;
          setTimeout(() => {
            this.fontsReady = true;
          }, 1000);
        })
      }
    },
    computed: {
      dataReady() {
        return this.fontsReady && this.imagesReady;
      },
      satrtStickers() {
        return this.$store.state.timePeriods[0].stickers;
      },
      timePeriods() {
        return this.$store.state.timePeriods;
      }
    }
  }
</script>

<style lang="scss" scoped>
.preloader {
  display: flex;
}
.number {
  user-select: none;
  font-family: 'Druk Text Wide Cyr';
  font-weight: 900;
  font-size: 12.5em;
  line-height: 90%;

  width: 1.1em;
  display: flex;
  align-items: center;
  justify-content: center;

  &:last-of-type {
    justify-content: flex-start;
  }

  @include mobile {
    font-size: 5.2em;
    width: 1.05em;
  }
}
</style>