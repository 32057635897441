<template>
  <div class="period" :style=" 'background:' + periodData.color ">
    <div class="period__header">
      <svg class="period__arrow">
        <use xlink:href="#mobile-period-arrow" />
      </svg>
      <h2 class="period__name">{{ periodData.name }}</h2>
    </div>
    <div class="period__stickers">
      <img 
        v-for="(sticker, index) in periodData.stickers"
        :key="sticker.id"
        :id="sticker.id"
        :src=" require('../assets/images/stickers/' + sticker.name + '.png') " 
        :class=" ['period__sticker-item', {'period__sticker-item_last': periodData.stickers.length - 1 === index && index % 2 === 0}]"
        @click="$emit('openSticker', $event.target.id); sendGa()"
      >
    </div>

    <span class="period__line" :style=" 'color:' + periodData.nameColor ">{{ periodData.name }}</span>
    <span class="period__line" :style=" 'color:' + periodData.nameColor ">{{ periodData.name }}</span>
  </div>
</template>

<script>
  export default {
    props: {
      periodData: Object,
      periodIndex: Number
    },
    methods: {
      sendGa() {
        window.ga('send',{hitType:'event',eventCategory:'all',eventAction:'click',eventLabel:'stickers',eventValue:1});
        window.ga('send',{hitType:'event',eventCategory:'all',eventAction:'click',eventLabel:'bigstickers',eventValue:1});
      }
    }
  }
</script>

<style lang="scss" scoped>
.period {
  display: none;
  position: relative;
  overflow: hidden;
  //padding: 5.25em 1.125em 1.25em;
  padding: 5.25em 1em 2em;
  @include mobile {
    display: block;
  }
  &__line {
    display: block;
    font-family: 'Druk Text Wide Cyr';
    font-weight: 900;
    font-size: 5.1875em;
    z-index: 0;
    position: absolute;
    transform: rotate(-90deg);
    width: 10em;
    &:first-of-type {
      top: 4em;
      //top: 12vh;
      left: -4.5em;
    }
    &:last-of-type {
      top: 7em;
      right: -4.4em;
    }
  }
  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2em;
    position: relative;
    z-index: 1;
  }
  &__arrow {
    width: 1.8em;
    height: 1.8em;
  }
  &__stickers {
    position: relative;
    z-index: 2;
    display: grid;
    grid-template-columns: repeat(2, 10.7em);
    gap: 1em 0.1em;
  }
  &__sticker-item {
    width: 100%;
  }
  &__sticker-item_last {
    width: 50%;
    grid-column: span 2;
    justify-self: center;
  }
}
</style>