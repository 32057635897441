<template>
  <div id="app" class="app" ref="app">

    <div class="gradients" v-if="isDesktop" ref="gradients">
      <div class="app__yellow" />
      <div class="app__blue" />
    </div>

    <Static style="opacity: 0;" ref="static" />
    <!-- <Static ref="static" /> -->
    <div class="desktop app" v-if="isDesktop">
      <transition mode="out-in" name="fade">
        <keep-alive include="Home">
          <component 
            :is="appState"
            :key="appState"
          />
        </keep-alive>
      </transition>
    </div>

    <div class="mobile app" v-if="!isDesktop">
      <Modal />
      <Home />
      <SideMenu />
      <MobilePeriod 
        v-for="(period, index) in timePeriods"
        :key="period.name"
        :periodData="period"
        :periodIndex="index"
        :id=" 'period' + index "
        @openSticker="showSticker"
        ref="periods"
      />
      <transition name="fade">
        <MobileStickers v-if="$store.state.isPeriodShown" :stickerId=" clickedSticker " />
      </transition>
      <MobileFooter />
    </div>

  </div>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import Static from './components/Static';
import Home from './components/Home';
import Main from './components/Main';
import MobilePeriod from './components/MobilePeriod';
import MobileFooter from './components/MobileFooter';
import MobileStickers from './components/MobileStickers';
import SideMenu from './components/SideMenu';
import Modal from './components/Modal';

gsap.registerPlugin(ScrollToPlugin);

export default {
  name: 'App',
    components: {
    Static,
    Home,
    Main,
    MobilePeriod,
    MobileFooter,
    MobileStickers,
    SideMenu,
    Modal
  },
  data() {
    return {
      clickedSticker: ''
    }
  },
  created() {
    const isMobile = document.documentElement.clientWidth < 1024 && document.documentElement.clientWidth < document.documentElement.clientHeight;
    this.$store.commit('SET_ISDESKTOP', !isMobile);
  },
  mounted() {
    if(!this.isDesktop) {
      document.addEventListener('scroll', this.showMobilePopup);
    }
  },
  watch: {
    isLoaded() {
      const tl = gsap.timeline();
      tl.fromTo(this.$refs.static.$el, {
        opacity: 0
      }, {
        opacity: 1,
        duration: 1,
        delay: 1
      }, 0)
      if(this.isDesktop) {
        tl.to(this.$refs.gradients, {
          duration: 0.5,
          opacity: 1
        }, '-=0.5')
      }
      !this.isDesktop ? this.sendGa() : 0;
    }
  },
  methods: {
    showSticker(id) {
      this.clickedSticker = id
      this.$store.commit('SET_ISPERIODSHOWN', true);
    },
    showMobilePopup() {
      if(window.pageYOffset) {
        this.$store.commit('SET_ISMODALOPEN', true)
      }
      document.removeEventListener('scroll', this.showMobilePopup)
    },
    sendGa() {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      }
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if(entry.isIntersecting) {
            const target = entry.target;
            const targetId = Number(target.id.slice(-1));
            window.ga('send',{hitType:'event',eventCategory:'all',eventAction:'click',eventLabel:`sect${targetId + 1}`,eventValue:1});
            console.log(targetId + 1, 'targetId + 1');
            //observer.unobserve(target);
          }
        })
      }, options)

      this.$refs.periods.forEach(item => {
        observer.observe(item.$el);
      })
    }
  },
  computed: {
    appState() {
      return this.$store.state.appState;
    },
    isLoaded() {
      return this.$store.state.isLoaded;
    },
    isDesktop() {
      return this.$store.state.isDesktop;
    },
    timePeriods() {
      return this.$store.state.timePeriods;
    }
  }
}
</script>

<style lang="scss">
@import './styles/base.scss';
.app {
  width: 100%;
  //height: 100%;
  // height: auto;
  min-height: 100vh;
  position: relative;
  @include mobile {
    position: relative;
    overflow-x: hidden;
    height: auto;
  }
  &__yellow {
    width: 40em;
    height: 34em;
    background: radial-gradient(50% 50% at 29.55% 75.14%, #FAD62A 0%, rgba(255, 255, 255, 0) 100%);
    transform: rotate(161.66deg);

    position: fixed;
    top:-18em;
    left: -7.7em;
  }
  &__blue {
    width: 38.6em;
    height: 25em;
    background: radial-gradient(50% 50% at 50% 50%, #B399FD 0%, rgba(255, 255, 255, 0) 100%);
    transform: rotate(152.23deg);

    position: fixed;
    bottom: -17em;
    right: -18em;
  }
}
.gradients {
  opacity: 0;
  @include mobile {
    display: none;
  }
}
</style>
