<template>
  <div class="shareAll">
    <div class="share" v-if="!footer">
      <span class="share__title">Поделиться</span>
      <div class="share__links">
        <p 
          v-for="social in networks"
          :key="social.name"
          class="share__link"
          @click="share(social)"
        >
          <svg :class="'share__icon share__icon_' + social.short">
            <use :xlink:href=" '#' + social.short " />
          </svg>
        </p>
      </div>
    </div>

    <div class="share__footer" v-else>
      <h2 class="share__footer-title">Рассказать <br> друзьям:</h2>
      <div class="share__footer-links">
        <p 
          v-for="social in networks"
          :key="social.name"
          class="share__footer-link"
          @click="share(social)"
        >
          <svg :class="'share__footer-icon share__footer-icon_' + social.short">
            <use :xlink:href=" social.icon " />
          </svg>
          <span class="share__footer-name">{{ social.rusName }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Share',
    props: {
      footer: Boolean
    },
    data() {
      return {
        publicPath: process.env.BASE_URL,
        networks: [
          {
            name: 'vkontakte',
            rusName: 'Вконтакте',
            short: 'vk',
            icon: '#vk',
            image: 'vk.png'
          },
          {
            name: 'facebook',
            rusName: 'Фейсбук',
            short: 'fb',
            icon: '#fb',
            image: 'fb.png'
          },
          {
            name: 'twitter',
            rusName: 'Твиттер',
            short: 'tw',
            icon: '#tw',
            image: ''
          }
        ]
      }
    },
    methods: {
      share (network) {
        const myUrl = window.location.protocol + '//' + window.location.hostname;
        //const myUrl = 'http://avitotech.sorryguyscdn.ru';
        let shareTitle = 'Узнайте, как развивались сервис и команда, которая его создавала';
        const tweat = 'AvitoTech исполнилось 5 лет! Узнайте историю команды, которая совершенствует сервисы Авито';
        const vkTitle = 'Узнайте, как команда инженеров помогла Авито стать одним из лидеров рунета';
        network.name === 'twitter' ? shareTitle = tweat : 0;
        network.name ==='vkontakte' ? shareTitle = vkTitle : 0;
        const shareText = '';
        const shareImg = `${myUrl}${this.publicPath}shareVK.png`;
        this.$share[network.name](myUrl, shareTitle, shareText, shareImg);

        this.sendGa(network.short);
        //this.$emit('share');
      },
      sendGa(type) {
        window.ga('send',{hitType:'event',eventCategory:'all',eventAction:'click',eventLabel:'share',eventValue:1});
        window.ga('send',{hitType:'event',eventCategory:'main',eventAction:'click',eventLabel:`share_${type}`,eventValue:1});
      }
    }
  }
</script>

<style lang="scss" scoped>
.share {
  position: relative;
  z-index: 99;
  display: flex;
  align-items: center;

  &__title {
    font-family: 'Druk Text Wide Cyr';
    font-weight: 800;
    font-size: 0.6em;
    letter-spacing: -0.025em;
    margin-right: 1.83em;
    @include mobile {
      font-size: 0.875em;
      margin-right: 1.1em;
    }
  }

  &__links {
    display: flex;
  }

  &__link {
    cursor: pointer;
    position: relative;
    display: block;
    width: 1.2em;
    height: 1.2em;
    background: black;
    border-radius: 50%;
    margin-right: 0.8em;
    overflow: hidden;
    transition: all .3s ease-out;

    &:last-of-type {
      margin: 0;
    }

    @include desktop {
      &:hover {
        transform: translateY(-30%);
      }
    }

    @include mobile {
      width: 2.5em;
      height: 2.5em;
      margin-right: 1em;
    }
  }

  &__icon {
    @include abs-center;

    &_vk{
      width: 0.47em;
      @include mobile {
        width: 1.06em;
      }
    } 
    &_fb {
      width: 0.27em;
      @include mobile {
        width: 0.56em;
      }
    } 
    &_tw {
      width: 0.54em;
      @include mobile {
        width: 1.18em;
      }
    }
  }
}
.share__footer {
  &-title {
    font-size: 1.5em;
    margin-bottom: 2.95em;
  }
  &-link {
    cursor: pointer;
    position: relative;
    display: block;
    width: 2.75em;
    height: 2.75em;
    background: black;
    border-radius: 50%;
    //margin-right: 1.5625em;
    //overflow: hidden;
    margin-bottom: 1.8em;
    display: flex;
    align-items: center;
  }
  &-icon {
    @include abs-center;

    &_vk{
      width: 1.06em;
    } 
    &_fb {
      width: 0.6em;
    } 
    &_tw {
      width: 1.2em;
    }
  }
  &-name {
    color: black;
    font-family: 'Druk Text Wide Cyr';
    font-weight: 800;
    letter-spacing: -0.025em;
    margin-left: 4em;
  }
}
</style>