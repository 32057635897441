import WebFont from 'webfontloader';

const preloadImages = (images) => {
  return new Promise((resolve, reject) => {
    if (!images || !images.length) reject(new Error('Список пуст!'));

    let ready = 0;
    const handler = () => {
      ready++;
      if (ready >= images.length) resolve();
    }
    images.forEach(url => {
      const img = new Image();
      img.src = url;
      img.onload = handler;
      img.onerror = handler;
      //console.log(img);
    })
  })
}

const preloadFonts = (fonts) => {
  return new Promise((resolve, reject) => {
    if (!fonts) reject(new Error('Не указаны шрифты'));
    WebFont.load(Object.assign({}, fonts, {
      classes: true,
      timeout: 2000,
      active: () => {
        resolve();
      }
    }))
  })
}

export { preloadImages, preloadFonts };